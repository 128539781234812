import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Image from 'gatsby-image'

import {
  HeroBlock,
  Decoration,
  Space,
  RichText,
  SEO,
} from '../components/shared'

import { FormBlock } from '../components/pageBlocks'

import {
  mobileVw,
  desktopVw,
  desktopBreakpoint,
  colors,
  fonts,
} from '../styles'

const Contact = ({
  location,
  data: { contentfulPageContact, contentfulGlobalSite },
}) => {
  const {
    heroblock,
    description,
    decorationMobile,
    decorationDesktop,
    hideForm,
    seoDescription,
    seoTitle,
    pageType
  } = contentfulPageContact

  const { blackBrushesAllRecipePages } = contentfulGlobalSite

  const newDescription = description
  
//   newDescription.raw.content.map((node) =>
//     node.nodeType === 'heading-3'
//       ? (node.content[0].id = node.content[0].value.split(' ').join('-'))
//       : null,
//   )

  useEffect(() => {
    console.log(pageType);
    if (pageType == "form") {
      const script = document.createElement('script')
      script.src =
        'https://d1a1ax4tcp3m3j.cloudfront.net/production/TWH/fr-fr/BF0544/fr-fr_BF0544.js'

      document.body.appendChild(script)
      return () => {
        document.body.removeChild(script)
      }
    }
  }, [])

  return (
    <StyledContactSection>
      <SEO
        description={seoDescription}
        title={seoTitle}
        location={location}
        pageType='Contact'
      />
      <HeroWrapper>
        <HeroBlock
          zIndex={2}
          gold
          data={{
            ...heroblock,
            marginBottom: ['0', '0'],
          }}
        />
        <StyledDecoration>
          {blackBrushesAllRecipePages &&
            blackBrushesAllRecipePages.map((item, i) => {
              return <Decoration key={i} data={item} />
            })}
        </StyledDecoration>
      </HeroWrapper>
      <Space l={1} s={1} />
      <StyledSpan>
        <span>
          <RichText json={newDescription} />
        </span>
      </StyledSpan>
      {hideForm ? null : (
        // <FormBlock
        //   firstName='Nom*'
        //   lastName='Prénom*'
        //   email='Adresse email*'
        //   phone='Téléphone'
        //   message='message*'
        // />
        <div />
      )}
      <UnileverFormWrapper>
        <section className='contact-us-twh-generic'>
          <form></form>
        </section>
      </UnileverFormWrapper>
      <Image
        className='mobileOnly'
        fluid={decorationMobile.image.fluid}
        alt={decorationMobile.image.title}
      />
      <Image
        className='desktopOnly'
        fluid={decorationDesktop.image.fluid}
        alt={decorationDesktop.image.title}
      />
    </StyledContactSection>
  )
}

const StyledContactSection = styled.div``

const StyledSpan = styled.div`
  a {
    text-decoration: underline;
  }
  padding: ${mobileVw(16)};
  span {
    font-size: ${mobileVw(16)};
  }
  word-break:break-word;
  @media (min-width: ${desktopBreakpoint}) {
    padding: ${desktopVw(50)} 0 ${desktopVw(50)} ${desktopVw(130)};
    max-width: ${desktopVw(900)};
    span {
      font-size: ${desktopVw(20)};
    }
  }
`

const HeroWrapper = styled.div`
  button {
    display: none;
  }

  .swiper-container {
    margin-left: 0;
  }

  h1 {
    font-size: ${mobileVw(36)};
  }

  @media (min-width: ${desktopBreakpoint}) {
    h1 {
      font-size: ${desktopVw(60)};
    }
  }

  .hero-slider {
    height: ${mobileVw(160)};

    @media (min-width: ${desktopBreakpoint}) {
      height: ${desktopVw(568)};
    }
  }
`

const StyledDecoration = styled.div`
  position: relative;
  background: #000;
  display: none;

  @media (min-width: ${desktopBreakpoint}) {
    display: block;
    padding-top: ${desktopVw(0)};
    margin-bottom: ${desktopVw(68)};
  }
`

const UnileverFormWrapper = styled.div`
  * {
    font-family: ${fonts.mrs} !important;
    font-size: ${mobileVw(15)} !important;
  }
  width: 90vw;
  margin: auto;

  label {
    font-family: ${fonts.tivoli} !important;
    font-size: ${mobileVw(21)} !important;
  }

  h3 {
    font-family: ${fonts.tivoli} !important;
    font-size: ${mobileVw(27)} !important;
    text-transform: uppercase;
  }

  input,
  select {
    border: none !important;
    border-radius: 0 !important;
    outline: none !important;
    border-bottom: ${mobileVw(2)} solid ${colors.mailleGold} !important;
  }

  select {
    -webkit-appearance: none !important;
    position: relative !important;
    padding-right: ${mobileVw(35)} !important;
  }

  .twh-dropdown {
    position: relative !important;
    ::after {
      content: url('data:image/svg+xml;charset=UTF-8, <svg width="14" height="12" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4 5.24998L0.25 1.49998L0.775 0.974976L4 4.19998L7.225 0.974976L7.75 1.49998L4 5.24998Z" fill="black"/>
</svg>') !important;
      display: block !important;
      position: absolute !important;
      top: 15px;
      right: 10px;
    }
  }

  textarea {
    border-radius: 0 !important;
  }

  .c-form-checkbox__label-copy {
    margin-left: ${mobileVw(6)} !important;
  }

  .c-form-submit-btn {
    background: ${colors.mailleGold} !important;
    color: ${colors.white} !important;
    border: none !important;
  }

  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(560)};
    margin: 0 0 0 ${desktopVw(130)};
    * {
      font-family: ${fonts.mrs} !important;
      font-size: ${desktopVw(15)} !important;
    }

    label {
      font-size: ${desktopVw(21)} !important;
    }

    h3 {
      font-size: ${desktopVw(27)} !important;
    }

    input,
    select {
      border: none !important;
      border-radius: 0 !important;
      outline: none !important;
      border-bottom: ${desktopVw(2)} solid ${colors.mailleGold} !important;
    }

    .twh-dropdown {
      position: relative !important;
      ::after {
        content: url('data:image/svg+xml;charset=UTF-8, <svg width="16" height="12" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4 5.24998L0.25 1.49998L0.775 0.974976L4 4.19998L7.225 0.974976L7.75 1.49998L4 5.24998Z"  fill="black"/>
</svg>') !important;
        display: block !important;
        position: absolute !important;
        top: 15px !important;
        right: 5px !important;
      }

      svg {
        width: ${desktopVw(16)};
        height: ${desktopVw(12)};
      }
    }

    .c-form-checkbox__label-copy {
      margin-left: ${desktopVw(6)} !important;
    }
  }
`

export const contactQuery = graphql`
  query Contact($id: String!, $node_locale: String!) {
    contentfulGlobalSite(node_locale: { eq: $node_locale }) {
      blackBrushesAllRecipePages {
        image {
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
        }
        positionDesktop
        positionMobile
        widthDesktop
        transformBlock
        widthMobile
        view
        name
      }
    }
    contentfulPageContact(id: { eq: $id }) {
      seoDescription
      seoTitle
      id
      slug
      pageType
      description {
        raw
      }
      hideForm
      decorationMobile {
        image {
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
        }
        positionDesktop
        positionMobile
        widthDesktop
        transformBlock
        widthMobile
        view
        name
      }
      decorationDesktop {
        image {
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
        }
        positionDesktop
        positionMobile
        widthDesktop
        transformBlock
        widthMobile
        view
        name
      }
      heroblock {
        title
        background
        breadcrumbsTitle
        breadcrumbs {
          title
          link {
            ...linkTarget
          }
        }
        richDescription {
            raw
        }
        imagesSlider {
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
        }
        decoration {
          image {
            fluid(maxWidth: 1800) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            title
          }
          positionDesktop
          positionMobile
          widthDesktop
          transformBlock
          widthMobile
          view
          name
        }
      }
    }
  }
`

export default Contact
